<template>
  <ModalOperator footer-hide :config="{ title: '岗位信息', width: 720 }">
    <h2>Java开发</h2>
    <div class="content">
      <span>岗位职责</span>
      <div>
        <p>1. 具备良好的编程技术能力；</p>
        <p>2. 能独立处理和解决所负责的任务；</p>
        <p>3. 根据开发进度和任务分配，完成相应模块软件的设计、开发、编程任务；</p>
        <p>4. 进行程序单元、功能的测试，查出软件存在的缺陷并保证其质量；</p>
        <p>5. 维护软件实质保持可用性和稳定性，大部分的技术问题可以自行解决。</p>
      </div>
    </div>
    <div class="content">
      <span>任职要求</span>
      <div>
        <p>1. 计算机、数学或相关专业本科及以上学历，2年以上工作经验；</p>
        <p>2. 熟悉jsp开发，熟练使用HTML、CSS、JAVASCRIPT等Web技术；</p>
        <p>3. 至少具有JSF、Struts、SpringWeb Flow之一的Java Web Framework开发经验；</p>
        <p>4. 熟悉Java数据库编程，有hibernate开发经验者优先；</p>
        <p>5. 能熟练掌握一种或多种目前主流的数据库（Oralce/DB2/SQL SERVER）;</p>
        <P>6. 熟悉软件架构、设计模式、具有软件工程理论知识者优先，条件优秀者条件可适当放宽</P>
      </div>
    </div>
    <div class="btn">
      <Button type="success" @click="toHR">发送简历给HR</Button>
    </div>
  </ModalOperator>
</template>

<script>
import ModalOperatorMixin from '@centit/ui-admin/src/components/ModalOperatorMixin'
import ModalOperator from '@centit/ui-admin/src/components/ModalOperator'
export default {
  name: 'modalRequirement',
  mixins: [
    ModalOperatorMixin,
  ],

  components: {
    ModalOperator,
  },

  methods: {
    toHR () {
      window.open('mailto:wang_jin@centit.com', '_blank')
    }
  }
}
</script>

<style lang="less">
.content{
  display: flex;
  margin:30px 0;
  span{
    margin-right:20px;
    font-weight: 600;
  }
}
.btn{
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.ivu-modal-header-inner{
  text-align: center;
}
.ivu-modal-footer{
  display: none;
}
</style>
