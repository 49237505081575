<template>
  <div :style="{height: bodyHeight + 'px'}" class="cases">
    <header class="header_center">
      <p>帮助中心</p>
      <Input
        suffix="ios-search"
        placeholder="请描述您的问题"
        class="header_input"
        @on-enter="search"
        @on-click="search"
        v-model="searchData"/>
    </header>
    <div class="help_log">
      <div v-for="(i, index) in arrLog" :key="index" @click="logClick(i)" @mouseenter="mouseEnter(i)" @mouseleave="mouseLeave">
            <img :src="i.text === name ? i.src[1] : i.src[0]" alt="">
        <p>{{i.text}}</p>
      </div>
      <!--<div @click="helpBook" class="helpBook">
        <img src="../../../assets/imageapp/sysc.png" alt="">
        <span>帮助手册</span>
      </div>
      <div class="helpBook " @click="upDateBook">
        <img src="../../../assets/imageapp/gxrz.png" alt="">
        <span>更新日志</span>
      </div>-->
    </div>
    <div class="quikly">
      <p class="title">快速入门教程</p>
      <div>
        <div v-for="v in arrQuikly" :key="v.key" class="quikly_img" @click="look(v.key)">
          <img :src="v.src" alt="">
          <span>{{v.text}}</span>
        </div>
      </div>
      <p class="quikly_look" @click="onClickMore">查看更多教程</p>
    </div>
    <div class="video" :class="{on:lookV}" @click="close">
      <video src="../../../static/p.mp4" :class="{on:lookT==='p'}" controls="controls" @click.stop/>
      <video src="../../../static/a.mp4" :class="{on:lookT==='a'}" controls="controls" @click.stop/>
      <video src="../../../static/c.mp4" :class="{on:lookT==='c'}" controls="controls" @click.stop/>
    </div>
    <footerS/>
  </div>
</template>

<script>
import footerS from '../footerS'
export default {
  name: 'homePageTab',
  components: {
    footerS,
  },
  data () {
    return {
      searchData: '',
      arrQuikly: [
        {
          key: 'p',
          text: '页面设计',
          src: require('../../../assets/image/ksss.png')
        },
        {
          key: 'a',
          text: 'API设计',
          src: require('../../../assets/image/ksss.png')
        },
        {
          key: 'c',
          text: '列表CRUD设计',
          src: require('../../../assets/image/ksss.png')
        }
      ],
      arrLog: [
        {
          src: [
            require('../../../assets/image/zdybd_1.png'),
            require('../../../assets/image/zdybd_2.png'),
          ],
          text: '自定义表单'
        },
        {
          src: [
            require('../../../assets/image/tjtb_1.png'),
            require('../../../assets/image/tjtb_2.png'),
          ],
          text: '统计图表'
        },
        {
          src: [
            require('../../../assets/image/ymdy_1.png'),
            require('../../../assets/image/ymdy_2.png'),
          ],
          text: '页面定义'
        },
        {
          src: [
            require('../../../assets/image/gsws_1.png'),
            require('../../../assets/image/gsws_2.png'),
          ],
          text: '格式文书'
        },
      ],
      name: '',
      lookV: false,
      lookT: '',
    }
  },
  methods: {
    helpBook () {
      window.open('http://ceshi.centit.com/doc/PageKnowedgeBase/N002', '_self')
    },
    reload () {
    },
    upDateBook () {
      window.open('http://ceshi.centit.com/doc/PageKnowedgeBase/N002/9e7c7fa8a6b7425e84b82cf19d58afb1', '_self')
    },
    onClickMore () {
      // window.open('http://ceshi.centit.com/locode/doc/#/PageKnowedgeBase/zp_Qn5R5ROSo4sf-eovoWA/ed60ee9aec084f05bece09f7eefb9246', '_self')
      // window.open('http://cloud.centit.com/locode/doc/#/PageKnowedgeBase/zp_Qn5R5ROSo4sf-eovoWA/2ce04f6a26e546d2a64d83ae0b26c797', '_self')
      window.open('http://cloud.centit.com/locode/doc/#/PageKnowedgeBase/zp_Qn5R5ROSo4sf-eovoWA/a94c48a0fb794456b50599cf00fad52b', '_self')
    },
    search () {
      // window.open('http://ceshi.centit.com/doc/searchAll/N002?docName=' + this.searchData, '_self')
      window.open('http://cloud.centit.com/locode/doc/#/PageKnowedgeBase/zp_Qn5R5ROSo4sf-eovoWA/ed60ee9aec084f05bece09f7eefb9246', '_self')
      // window.open('http://cloud.centit.com/locode/doc/#/searchAll?docName=' + encodeURI(this.searchData), '_self')
    },
    logClick (i) {
      switch (i.text) {
        case '自定义表单':
          // window.open('http://ceshi.centit.com/doc/PageKnowedgeBase/N002/095a8628eecd4557afb61cc9ea9fe45d', '_self')
          window.open('http://cloud.centit.com/locode/doc/#/PageKnowedgeBase/zp_Qn5R5ROSo4sf-eovoWA/a461c4d00307441b93d163d3f22e79b0', '_self')
          break
        case '统计图表':
          // window.open('http://ceshi.centit.com/doc/PageKnowedgeBase/N002/96b3f64117994c0c8cd8666adc5b0352', '_self')
          window.open('http://cloud.centit.com/locode/doc/#/PageKnowedgeBase/zp_Qn5R5ROSo4sf-eovoWA/eb5937422a4e4e55a0cf885ed3a69736', '_self')
          break
        case '页面定义':
          // window.open('http://ceshi.centit.com/doc/PageKnowedgeBase/N002/96b3f64117994c0c8cd8666adc5b0352', '_self')
          window.open('http://cloud.centit.com/locode/doc/#/PageKnowedgeBase/zp_Qn5R5ROSo4sf-eovoWA/2ae9b643e039463c839effa08eafb3ba', '_self')
          break
        case '格式文书':
          // window.open('http://ceshi.centit.com/doc/PageKnowedgeBase/N002/96b3f64117994c0c8cd8666adc5b0352', '_self')
          window.open('http://cloud.centit.com/locode/doc/#/PageKnowedgeBase/zp_Qn5R5ROSo4sf-eovoWA/bab71a1a44e5457d9e407188693f3df3', '_self')
          break
      }
    },
    mouseEnter (i) {
      this.name = i.text
    },
    mouseLeave () {
      this.name = ''
    },
    look (type) {
      this.lookT = type
      this.lookV = true
    },
    close () {
      this.lookV = false
      this.lookT = ''
    }
  },
  props: {
    bodyHeight: Number,
  },
}
</script>

<style lang="less">
  p.title{
    font-size:31px;
    font-weight: 800;
    color:#2C3C5E;
    margin-bottom:15px;
  }
.header_center{
  background: url("../../../assets/image/help_banner.png") no-repeat;
  background-size: 100% 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p{
    color:#fff;
    font-size:30px;
    font-weight: 700;
  }
  .header_input{
    margin-top:10px;
    width:350px;
    .ivu-input:hover{
      border-color: transparent;
    }
  }
}
  .help_log{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #ffffff;
    padding:20px 0;
    div{
      display: flex;
      align-items: center;
      height: 110px;
      border: 1px dotted #586580;
      width: 180px;
      justify-content: space-evenly;
      cursor: pointer;
      flex-direction: column;
      img{
        width: 70px;
      }
      span{
        font-size:20px;
        font-weight: 600;
      }
    }
    div:hover {
      background: #0DB265;
      border:none !important;
      color:#fff !important;
    }
  }
  .quikly{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F4F7FE;
    padding:50px 0;
    > div{
      display: flex;
      .quikly_img{
        display: flex;
        justify-content: center;
        position: relative;
        cursor: pointer;
        span{
          position: absolute;
          bottom: 17px;
          font-size: 21px;
          font-weight: 600;
        }
        img{
          width:240px;
        }
      }
    }
    .quikly_look{
      background: #0BB867;
      color: #ffffff;
      padding: 15px 30px;
      font-size: 15px;
      border-radius: 5px;
      margin-top:15px;
      cursor: pointer;
    }
  }
  .helpBook{
    cursor:pointer;
  }
  .choose{
    background: #0DB265;
    border:none !important;
    color:#fff !important;
  }
  .video {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background: #00000070;
    z-index: 101;
    &.on {
      display: flex;
    }
    &:after {
      content: 'X';
      position: absolute;
      top: 0;
      right: 0;
      width: 70px;
      height: 70px;
      color: #ddd;
      font-size: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 0 50%;
      cursor: pointer;
      transition: all 0.3s;
    }

    video {
      max-width: 90%;
      max-height: 90%;
      border-radius: 10px;
      display: none;
      &.on {
        display: block;
      }
    }
  }
</style>
