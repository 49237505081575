<template>
  <div>
    <footer>
      <div class="logo" @click="toCompany"><img src="../../assets/image/company_logo.png" alt=""></div>
      <div class="company_title">
        <p>
          咨询热线 <span>025-84207500-100</span>
        </p>
        <p>
          <a href="http://beian.miit.gov.cn/">苏ICP备10211477号-3</a>&nbsp;&nbsp;|&nbsp;&nbsp;@2018 江苏南大先腾信息产业股份有限公司&nbsp;&nbsp;版权所有
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'footer',
  methods: {
    toCompany () {
      window.open('http://www.centit.com/', '_blank')
    }
  }
}
</script>

<style scoped lang="less">
  footer{
    height:150px;
    width:100%;
    background: -moz-linear-gradient(top,  #173030 0%, #021208 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#173030), color-stop(100%,#021208));
    background: -webkit-linear-gradient(top,  #173030 0%,#021208 100%);
    background: -o-linear-gradient(top,  #173030 0%,#021208 100%);
    background: -ms-linear-gradient(top,  #173030 0%,#021208 100%);
    background: linear-gradient(to bottom,  #173030 0%,#021208 100%);
    color:#ffffff;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    margin-bottom:60px;
  .logo{
    cursor: pointer;
  }
  .company_title {
  p {
    font-size:11px;
    text-align: right;
  span {
    font-size:18px;
  }
  }
  }
  }
</style>
