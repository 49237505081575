<template>
  <div :style="{height: bodyHeight + 'px'}" class="cases">
    <header></header>
    <div class="contents">
      <div class="content_main">
        <div class="content_main_left"></div>
        <div class="content_main_right img_erm_div">
          <div>
            <p>进入试用页面时, 需要登录方可进入应用</p>
            <p>(1)账号：gm-test<span style="text-indent: 20px;display: inline-block">密码：test123</span></p>
            <!-- <p>(1)普通角色：test<span style="text-indent: 20px;display: inline-block">密码：000000</span></p>
            <p class="textIndex">HR角色：test1<span style="text-indent: 20px;display: inline-block">密码：000000</span></p>
            <p class="textIndex">部门经理角色账号：test2<span style="text-indent: 20px;display: inline-block">密码：000000</span></p>
            <p class="textIndex"><i>试用数据库链接：</i><span>jdbc:mysql://192.168.134.7:3310/locodedb?useUnicode=true&characterEncoding=utf-8</span></p>
            <p class="textIndex">试用数据库用户：locodeuser</p>
            <p class="textIndex">试用数据库密码：locodeuser</p> -->
            <p>(2)试用自定义表单可视化创建, 轻松拖拽组件, 搭建您需要的应用</p>
          </div>
          <div class="content_main_right_middle">
            <p>备注:</p>
            <p>1.请在周一至周日8:30-23.:00进行试用</p>
            <p>2.试用期间产生的数据每日恢复到试用的初始状态</p>
            <p>3.在试用过程中遇到问题, 请<span @click="touchOurs">联系我们</span></p>
          </div>
          <div>
            <Button type="primary" @click.native="onClick" shape="circle" ghost>开始试用</Button>
          </div>

        </div>
          <div class="img_ewm">
              <img src="../../../assets/image/ewm.png" alt="">
              <p>扫码可下载Android客户端</p>
          </div>
      </div>
    </div>
    <footerS/>
  </div>
</template>

<script>
import footerS from '../footerS'
export default {
  name: 'homePageTab',
  components: {
    footerS,
  },
  props: {
    bodyHeight: Number,
  },
  methods: {
    reload () {},
    onClick () {
      // const routeData = this.$router.resolve({ path: '/platform/' })
      // window.open(routeData.href, '_blank')
      window.open('https://cloud.centit.com/locode', '_blank')
    },
    touchOurs () {
      this.$emit('toOurs', 'aboutOurs')
    }
  }
}
</script>

<style lang="less" scoped>
  header{
    background: url("../../../assets/image/banner_sys.png") no-repeat;
    background-size: 100% 100%;
    height: 200px;
  }
  .contents{
    display: flex;
    justify-content: center;
    background: #F4F7FE;
    .content_main{
      background: url("../../../assets/image/sy_bg.png") no-repeat;
      background-size: 100% 100%;
      width:1050px;
      height:550px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .content_main_left{
        background: url("../../../assets/image/sy_ct.png") no-repeat;
        background-size: 100% 100%;
        width: 400px;
        height: 300px;
      }
      .content_main_right{
        font-size:14px;
        div.content_main_right_middle{
          margin:22px 0;
          span{
            cursor: pointer;
            color: #6B8CF8;
          }
        }
        p{
          line-height: 30px;
        }
        .ivu-btn-ghost.ivu-btn-primary{
          padding:5px 32px;
        }
      }
    }
  }
  .textIndex{
    text-indent:22px;
    i{
      font-style:normal;
      position: relative;
      top: -29px;
    }
    span{
      display: inline-block;
      width: 335px;
      text-indent: 0;
    }
  }
  /*.img_erm_div{
    position: relative;
    .img_ewm {
      position: absolute;
      left: 100%;
      top: 28%;
      img{
        width:180px;
      }
    }
  }*/
  .img_erm_div{
    margin-left: 16px;
  }
  .img_ewm {
      img{
        width:180px;
      }
    p{
      line-height: 30px;
      font-size: 14px;
      text-indent: 3px;
    }
    }
</style>
