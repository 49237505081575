<template>
      <full-page :options="options" class="home_tab" ref="fullpage">
        <header class="section headerH">
          <div class="slide" v-for="(i, index) in headerImg" :key="index">
            <img :src="i" alt="">
          </div>
        </header>
          <div class="section bus_model headerH">
              <div class="page_esign_bg"></div>
              <p class="title">业务建模</p>
              <div>
                  <div class="bus_model_left">
                      <div v-for="(i, index) in busModel" :key="index">
                          <h2>{{ i.title }}</h2>
                          <div class="bus_model_left_shape">
                              <div class="bus_model_left_img">
                                  <img :src="i.src" alt="">
                              </div>
                              <div class="bus_model_left_title">
                                  <span>{{ i.titleSpanOne }}</span>
                                  <span>{{ i.titleSpanTwo }}</span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="bus_model_right">
                      <img src="../../../assets/image/bus_model.png" alt="">
                  </div>
              </div>
          </div>
          <div class="section graphic headerH">
              <div class="varied">
                  <p class="title varied_p"><span>数据处理引擎（API网关）</span>
                      <span class="chuild" v-for="(i, index) in variedNames" :key="index"
                            :class="i.type === variedType ? 'choosees' : ''"
                      @click="showVariedType(i.type, index)">{{i.content}}</span>
                  </p>
                  <div class="slide varied_div">
                      <div class="varied_imgs">
                          <img v-for="(i ,index) in variedImg" :key="index" :src="i" alt="">
                      </div>
                  </div>
                  <div class="slide engine">
                      <div class="engine_content">
                          <div v-for="(i ,index) in engineImg" :key="index" class="engine_div">
                              <div>
                                  <h2>{{i.title}}</h2>
                                  <div>
                                      <p v-for="(c, o) in i.content" :key="o">{{c}}</p>
                                  </div>
                              </div>
                              <img :src="i.src" alt="">
                          </div>
                      </div>
                  </div>
              </div>
              <!--<div class="slide engine">
                  <p class="title">数据处理引擎（API网关）</p>
                  <div class="engine_content">
                      <div v-for="(i ,index) in engineImg" :key="index" class="engine_div">
                          <div>
                              <h2>{{i.title}}</h2>
                              <div>
                                  <p v-for="(c, o) in i.content" :key="o">{{c}}</p>
                              </div>
                          </div>
                          <img :src="i.src" alt="">
                      </div>
                  </div>
              </div>-->
          </div>
          <div class="section page_esign">
              <div class="page_esign_bg"></div>
              <div class="page_esign_all">
                  <div class="page_esign_left">
                      <p class="title">页面设计</p>
                      <div>
                      <span  v-for="(i, index) in pageEsignArr"
                             :key="index"
                             :class="i.content === pageEsignName ? 'choosees' : ''"
                             @click="changeColor(i.content, index)">
                          {{i.count}} <span class="span_content">{{i.content}}</span>
                      </span>
                      </div>
                  </div>
                  <div class="page_esign_right">
                      <div v-for="(i, index) in pageEsignArrRight" :key="index" class="slide">
                          <img  :src="i.src" alt="">
                          <div class="page_esign_right_show">
                              <h1>{{i.title}}</h1>
                              <div class="page_right_show">
                                  <img :src="i.beforesrc" alt="">
                                  <span v-for="(c, o) in i.contentArr" :key="o">{{c}}</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="section work_flow">
                  <div class="work_flow_left">
                      <p class="title">工作流引擎</p>
                      <div>
                      <span v-for="(i, index) in workFlowArr" :key="index"
                            :class="i.content === workFlowName ? 'choosees' : ''"
                            @click="changeColorWork(i.content, index)">
                          {{i.count}} <span class="span_content">{{i.content}}</span>
                      </span>
                      </div>
                  </div>
                  <div class="work_flow_right">
                      <div v-for="(i, index) in workFlowArrRight" :key="index" class="slide">
                          <img  :src="i.src" alt="">
                          <div class="page_esign_right_show">
                              <h1>{{i.title}}</h1>
                              <div class="page_right_show">
                                  <img :src="i.beforesrc" alt="">
                                  <span v-for="(c, o) in i.contentArr" :key="o">{{c}}</span>
                              </div>
                          </div>
                      </div>
                  </div>
          </div>
          <div class="section headerH commun">
              <div class="page_esign_bg"></div>
              <p class="title">项目沟通管理</p>
              <div class="commun_div">
                  <div v-for="(i ,index) in communArr" :key="index">
                      <img :src="i.src" alt="">
                      <h3>{{i.title}}</h3>
                      <p>{{i.content}}</p>
                  </div>
              </div>
          </div>
          <div class="section headerH extend">
              <p class="title">丰富的扩展能力</p>
              <div class="extend_content">
                  <div v-for="(i, index) in extendArr" :key="index">
                      <img :src="i.src" alt="">
                      <h2>{{i.title}}</h2>
                      <p>{{i.explain}}</p>
                      <span v-for="(c, o) in i.content" :key="o">
                          <i :style="i.style"></i>
                          <u>{{c}}</u>
                      </span>
                  </div>
              </div>
          </div>
          <div class="section  each">
              <p class="title">各自预定义的模板
                  <span class="eachsss">PaaS平台的各个阶段都有丰富的模板，从细节到整体</span></p>
              <div class="each_div_img">
                  <img src="../../../assets/image/each.png" alt="" class="each_img">
              </div>
              <footerS class="footers_homepage"/>
          </div>
        <!--<div class="graphic section headerH ">
          <div class="graphic_img " >
            <div v-for="(i, index) in graphicImg" :key="index" class="slide">
              <img :src="i" alt="" >
            </div>
          </div>
        </div>
        <div class="expansion_capability section headerH headeH_sp">
          <p class="title">强大的扩展能力</p>
          <div>
            <ul>
              <li>
                <img src="../../../assets/image/icon_1.png" alt="">
                <p>
                  前后端分离的设计风格
                </p>
              </li>
              <li>
                <img src="../../../assets/image/icon_2.png" alt="">
                <p>
                  可以植入前端代码
                </p>
              </li>
              <li>
                <img src="../../../assets/image/icon_3.png" alt="">
                <p>
                  可以植入后端代码
                </p>
              </li>
              <li>
                <img src="../../../assets/image/icon_4.png" alt="">
                <p>
                  多种组件单独使用
                </p>
              </li>
              <li>
                <img src="../../../assets/image/icon_5.png" alt="">
                <p>
                  可以插入自定义表单组件
                </p>
              </li>
              <li>
                <img src="../../../assets/image/icon_6.png" alt="">
                <p>
                  可以插入自定义逻辑组件
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="varied section headerH ">
          <div class="varied_left">
            <p class="title">多种模板可供下载使用</p>
            <span>平台提供多种类型模板可供下载使用，更方便灵活地搭建您的业务应用</span>
            <ul>
              <li @click="chooseColor(i)" v-for="(i, index) in arrVaried" :class="i.title === name ? 'choose' : ''" :key="index">
                <img :src="i.title === name ? i.src[1] : i.src[0]" alt="">
                <span>{{i.title}}</span>
              </li>
            </ul>
          </div>
          <div>
            <img src="../../../assets/image/bit_map.png" alt="">
          </div>
        </div>
        <div class="section">
          <div class="coach headerH headeH_sp">
            <p class="title">研发管理辅助功能</p>
            <div>
              <ul>
                <li>
                  <img src="../../../assets/image/icon_file.png" alt="">
                  文档管理
                </li>
                <li>
                  <img src="../../../assets/image/icon_chat.png" alt="">
                  沟通管理
                </li>
                <li>
                  <img src="../../../assets/image/icon_task.png" alt="">
                  任务管理
                </li>
                <li>
                  <img src="../../../assets/image/icon_edition.png" alt="">
                  版本管理
                </li>
              </ul>
            </div>
          </div>
          <footerS class="footers"/>
        </div>-->
      </full-page>
</template>

<script>
import footerS from '../footerS'
export default {
  name: 'homePageTab',
  components: {
    footerS
  },
  props: {
    showNav: Boolean,
  },
  mounted () {
    this.options.navigation = this.showNav
  },
  data () {
    return {
      name: '表单模板',
      graphicImg: [
        require('../../../assets/image/illus_11.png'),
        require('../../../assets/image/illus_22.png'),
        require('../../../assets/image/illus_33.png'),
        require('../../../assets/image/illus_44.png'),
      ],
      headerImg: [
        require('../../../assets/image/banner01.png'),
        require('../../../assets/image/banner2.png'),
      ],
      arrVaried: [
        {
          src: [
            require('../../../assets/image/icon_b_1.png'),
            require('../../../assets/image/icon_g_1.png'),
          ],
          title: '表单模板',
          class: ''
        },
        {
          src: [
            require('../../../assets/image/icon_b_2.png'),
            require('../../../assets/image/icon_g_2.png'),
          ],
          title: '门户模板',
          class: ''
        },
        {
          src: [
            require('../../../assets/image/icon_b_3.png'),
            require('../../../assets/image/icon_g_3.png'),
          ],
          title: '大屏展示模板',
          class: ''
        },
        {
          src: [
            require('../../../assets/image/icon_b_4.png'),
            require('../../../assets/image/icon_g_4.png'),
          ],
          title: '应用模板',
          class: ''
        },
        {
          src: [
            require('../../../assets/image/icon_b_5.png'),
            require('../../../assets/image/icon_g_5.png'),
          ],
          title: '样式模板',
          class: ''
        },
      ],
      options: {
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6', 'page7', 'page8'],
        continuousVertical: true,
        controlArrows: false,
        loopHorizontal: true,
        css3: true,
        scrollOverflow: true,
        afterLoad: this.afterLoad,
        slidesNavigation: true,
        scrollingSpeed: '1000',
        afterSlideLoad: this.afterSlideLoad,
        navigation: true,
      },
      counter: 0,
      valueItem: 0,
      timer: null,
      busModel: [
        {
          title: '关系数据库的表结构管理',
          src: require('../../../assets/image/shape_1.png'),
          titleSpanOne: '表之间的关系定义',
          titleSpanTwo: '表结构重构'
        },
        {
          title: '非关系数据库、消息队列',
          src: require('../../../assets/image/shape_2.png'),
          titleSpanOne: 'Elastic Search中的索引',
          titleSpanTwo: '消息中间件的队列'
        },
      ],
      variedImg: [
        require('../../../assets/image/varied_one.png'),
        require('../../../assets/image/varied_two.png'),
        require('../../../assets/image/varied_three.png'),
        require('../../../assets/image/varied_four.png')
      ],
      variedType: '01',
      variedNames: [
        {
          type: '01',
          content: '01 多种资源管理',
        },
        {
          type: '02',
          content: '02 服务逻辑编排',
        }
      ],
      engineImg: [
        {
          title: '内部逻辑计算',
          content: [],
          src: require('../../../assets/image/engine_one.png')
        },
        {
          title: '丰富的返回结果',
          content: ['json数据', '格式文书'],
          src: require('../../../assets/image/engine_two.png')
        },
        {
          title: '灵活的交互接口',
          content: ['调用资源中的各种资源', '数据库', '消息中间件', '服务调用'],
          src: require('../../../assets/image/engine_three.png')
        },
        {
          title: '多种调度（触发）机制',
          content: ['http api接口', '定时调度', '消息触发'],
          src: require('../../../assets/image/engine_four.png')
        },
      ],
      communArr: [
        {
          src: require('../../../assets/image/commun_one.png'),
          title: '讨论板',
          content: '可以用于讨论项目的技术路线和业务方向，项目团队所有人可见',
        },
        {
          src: require('../../../assets/image/commun_two.png'),
          title: '即时通讯',
          content: '团队成员可以一对一单个沟通',
        },
        {
          src: require('../../../assets/image/commun_three.png'),
          title: '项目文档管理',
          content: '项目需求文档、技术方案、阶段性成果等等',
        },
      ],
      extendArr: [
        {
          src: require('../../../assets/image/extend_1.png'),
          title: '前端扩展',
          explain: '',
          content: [
            '嵌入js脚本实现个性化的业务逻辑',
            '嵌入css实现个性化的样式',
          ],
          style: {
            background: '#1FB5F5',
          }
        },
        {
          src: require('../../../assets/image/extend_2.png'),
          title: '后台扩展',
          explain: '',
          content: [
            '数据处理引擎提供一个嵌入后台逻辑处理脚本（js）的组件实现特殊的业务逻辑操作',
            '通过java bean方式扩展',
            '通过http服务扩展',
          ],
          style: {
            background: '#16D897',
          }
        },
        {
          src: require('../../../assets/image/extend_3.png'),
          title: '可替换的基础服务接口组件',
          explain: '基础服务都可以通过实现预先定义的接口组件替换',
          content: [
            '消息组件',
            '日志组件',
          ],
          style: {
            background: '#FAD443',
          }
        },
      ],
      pageEsignArr: [
        {
          count: '01',
          content: '数据采集表单'
        },
        {
          count: '02',
          content: '丰富的框架布局组件'
        },
        {
          count: '03',
          content: '数据表格组件'
        },
        {
          count: '04',
          content: '图表展示组件'
        },
      ],
      pageEsignArrRight: [
        {
          src: require('../../../assets/image/dsn01.gif'),
          title: '数据采集表单',
          contentArr: [
            '丰富的控件, 基本控件 文本录入、下拉框、时间控件',
            '表单和后台数据处理引擎对接'
          ],
          beforesrc: require('../../../assets/image/shape_1.png')
        },
        {
          src: require('../../../assets/image/dsn02.gif'),
          title: '丰富的框架布局组件',
          contentArr: [
            '横向、纵向分栏布局',
            '表单布局、列表布局、多页签布局、日历布局、iFrame布局'
          ],
          beforesrc: require('../../../assets/image/shape_1.png')
        },
        {
          src: require('../../../assets/image/dsn03.gif'),
          title: '数据表格组件',
          contentArr: [
            '丰富的表格表头设计',
            '灵活的表格数据处理方式'
          ],
          beforesrc: require('../../../assets/image/shape_1.png')
        },
        {
          src: require('../../../assets/image/dsn04.gif'),
          title: '图表展示组件',
          contentArr: [
            '丰富的图表组件',
            '灵活的数据对接方式'
          ],
          beforesrc: require('../../../assets/image/shape_1.png')
        }
      ],
      pageEsignName: '数据采集表单',
      workFlowArr: [
        {
          count: '01',
          content: '流程的流转'
        },
        {
          count: '02',
          content: '多样的节点类别'
        },
        {
          count: '03',
          content: '灵活的任务分配机制'
        },
        {
          count: '04',
          content: '丰富的辅助功能'
        },
      ],
      workFlowArrRight: [
        {
          src: require('../../../assets/image/ilus01.gif'),
          title: '流程的流转',
          contentArr: [
            '分支',
            '并行',
            '多实例',
          ],
          beforesrc: require('../../../assets/image/shape_3.png')
        },
        {
          src: require('../../../assets/image/ilus02.gif'),
          title: '多样的节点类别',
          contentArr: [
            '交互节点, 和自定义表单对接、和第三方页面对接',
            '自动运行节点，运行内部脚本、调用第三方接口',
          ],
          beforesrc: require('../../../assets/image/shape_1.png')
        },
        {
          src: require('../../../assets/image/ilus03.gif'),
          title: '灵活的任务分配机制（权限体系）',
          contentArr: [
            '基于机构数的表达式',
            '关联行政角色',
            '关联岗位角色',
            '关联办件角色',
          ],
          beforesrc: require('../../../assets/image/shape_4.png')
        },
        {
          src: require('../../../assets/image/ilus04.gif'),
          title: '丰富的辅导功能',
          contentArr: [
            '多种计时形式',
            '预警设置与预警处理方式',
          ],
          beforesrc: require('../../../assets/image/shape_1.png')
        },
      ],
      workFlowName: '流程的流转',
    }
  },
  methods: {
    chooseColor (i) {
      this.name = i.title
    },
    reload () {
    },
    afterLoad (anchorLink, index) {
      if (index.anchor === 'page8') {
        // eslint-disable-next-line no-undef
        clearInterval(this.timer)
        this.$refs.fullpage.api && this.$refs.fullpage.api.setAllowScrolling(false, 'down')
        this.$refs.fullpage.api.setAllowScrolling(true, 'up')
      } else if (index.anchor === 'page1') {
        this.$refs.fullpage.api && this.$refs.fullpage.api.setAllowScrolling(false, 'up')
        this.$refs.fullpage.api && this.$refs.fullpage.api.setAllowScrolling(true, 'down')
        this.$refs.fullpage.$parent.options.loopHorizontal = true
        const that = this
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          that.$refs.fullpage && that.$refs.fullpage.api.moveSlideRight()
        }, 5000)
      } else {
        clearInterval(this.timer)
        this.$refs.fullpage.api && this.$refs.fullpage.api.setAllowScrolling(true, 'up')
        this.$refs.fullpage.api && this.$refs.fullpage.api.setAllowScrolling(true, 'down')
      }
    },
    changeColor (i, count) {
      this.pageEsignName = i
      this.$refs.fullpage.api.silentMoveTo('page4', count)
    },
    changeColorWork (i, count) {
      this.workFlowName = i
      this.$refs.fullpage.api.silentMoveTo('page5', count)
    },
    afterSlideLoad (anchorLink, index, slideAnchor, slideIndex) {
      if (anchorLink.anchor === 'page5') {
        this.workFlowName = this.workFlowArr[slideAnchor.index].content
      } else if (anchorLink.anchor === 'page4') {
        this.pageEsignName = this.pageEsignArr[slideAnchor.index].content
      } else if (anchorLink.anchor === 'page3') {
        this.variedType = this.variedNames[slideAnchor.index].type
      }
    },
    showVariedType (i, count) {
      this.variedType = i
      this.$refs.fullpage.api.silentMoveTo('page3', count)
    },
  },
}
</script>

<style lang="less">
  #fp-nav ul li a span, .fp-slidesNav ul li a span{
    background: #a5a0a0 !important;
  }
  #fp-nav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li a.active span, .fp-slidesNav ul li:hover a.active span{
    background: #0ABD62 !important;
  }
  .home_tab{
    p.title{
      font-size:31px;
      font-weight: 800;
      color:#2C3C5E;
      span{
        display: block;
      }
      span.chuild{
        color:#999;
        display: inline-block;
        font-size: 20px;
        font-weight: 600;
        margin-top:15px;
        text-align: center;
        margin-left:20px;
        cursor: pointer;
      }
    }
    header{
      background: #0ABD62;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .fp-slidesNav ul li a.active span{
        background: #fff;
      }
      .fp-slidesNav ul li a span{
        background: rgba(255,255,255,.6)
      }
      .text_description{
        color:#ffffff;
        p{
          font-size:44px;
          font-weight: 700;
          text-align: center;
        }
        div{
          margin: 296px 0 30px 0;
          font-size: 16px;
        }
      }
      #createds {
        color: #19be6b;
        background-color: #fff;
        border-color: #19be6b;
        width: 110px;
        height: 40px;
      }
      img{
        height:100%;
      }
    }
    .graphic{
      .graphic_title{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top:50px;
        span{
          color:#BAC0CB;
          font-size:14px;
          width: 776px;
          text-align: center;
          margin-top: 20px;
        }
        .graphic_title_look{
          font-size:32px;
          margin-top:8px;
        }
      }
      .graphic_img{
        background: url("../../../assets/image/bg02.png") no-repeat -112px 38px;
        background-size:114% 100%;
        img{
          height: 62%;
          margin-top: 33px;
        }
      }
      .fp-slidesNav ul li a.active span{
        background: #0ABD62;
      }
      .fp-slidesNav ul li a span{
        background: #a5a0a0
      }
    }
    .expansion_capability{
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #F4F7FE;
      justify-content: center;
      .title{
        margin-bottom:55px;
      }
      div {
        display: flex;
        justify-content: center;
        width:100%;
        ul {
          display: flex;
          width:1000px;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            list-style: none;
            background: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 327px;
            margin-top: 10px;
            padding-bottom: 12px;
            img{
              height: 135px;
              width: 160px;
            }
            p{
              font-size: 16px;
              font-weight: 700;
              color:#2C3C5E;
            }
          }
        }
      }
    }
    .varied{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .varied_left{
        width:343px;
        > span{
          width:300px;
          margin:10px 0;
          display: inline-block;
          color:#BAC0CB;
        }
        ul {
          li {
            list-style: none;
            width: 90%;
            height: 50px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            padding-left:15px;
            cursor: pointer;
            span{
              padding-left:10px;
            }
          }
          .choose{
            border:1px solid #57C586;
            color:#57C586;
            border-radius: 5px;
            background: #F0FFF6;
          }
        }
      }
    }
    .coach{
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #F4F7FE;
      justify-content: center;
      margin-bottom: -260px;
      height: 100%;
      padding-bottom: 240px;
      .title{
        margin-bottom:55px;
      }
      ul {
        display: flex;
        width: 700px;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;

        li {
          list-style: none;
          width: 335px;
          background: #fff;
          display: flex;
          align-items: center;
          height: 100px;
          padding-left: 26px;
          border-radius: 5px;
          margin-bottom:10px;
          font-size:20px;
          font-weight: 600;
          img{
            padding-right:40px;
          }
        }
      }
    }
  }
.headerH{
  .fp-tableCell{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .graphic_img{
    .fp-tableCell{
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}
  .headeH_sp{
    .fp-tableCell{
      flex-direction: column;
    }
  }
  .fp-slidesNav.fp-bottom {
    bottom: 57px !important;
    left: 49% !important;
  }
  .all_flex{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .bus_model{
    background:#F4F7FE;
    .fp-tableCell{
      display:flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      >div{
        display: flex;
        flex-direction: row;
      }
    }
    .bus_model_left{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .bus_model_left_shape{
        display: flex;
        margin-top:18px;
      }
      .bus_model_left_title{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        margin-left: 16px;
        height: 63px;
        position: relative;
        top: -6px;

      }
    }
    .bus_model_right{
      margin-left: 60px;
      width: 600px;
    }

  }
  .varied{
    display: flex !important;
    .varied_p{
      position: relative;
      top: 67px;
      z-index: 100;
    }
    .varied_div{
      display: flex !important;
      flex-wrap: wrap;
      justify-content: center;
      width:1050px;
      .varied_imgs{
        width:1050px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        img{
          width:500px;
        }
      }
    }
  }
  .engine{
    .engine_content{
      display: flex;
      width: 1050px;
      justify-content: space-between;
      .engine_div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        h3{
          text-align: center;
        }
        div{
          margin-top:10px;
          p{
            font-size:12px;
            color:#999;
            text-align: center;
            line-height: 25px;
          }
        }
      }
    }
  }
  .commun{
    display:flex;
    background:#F4F7FE;
    .commun_div{
      display: flex;
      justify-content: space-evenly;
      width: 900px;
      font-size:14px;
      position: relative;
      z-index: 3;
      div{
        display: flex;
        flex-direction: column;
        align-items: center;
        background:#fff;
        border-radius: 6px;
        padding-bottom:31px;
        box-shadow: #eaeffa 1px 1px 9px 1px;
        p{
          width: 166px;
          text-align: center;
          color: #999;
          margin-top:14px;
        }
      }
    }
  }
  .extend{
    display: flex;
    background:url('../../../assets/image/bg_extand.png') no-repeat bottom;
    .extend_content{
      display:flex;
      width:1050px;
      justify-content: space-around;
      div{
        display: flex;
        flex-direction: column;
        font-size:14px;
        img{
          width:100px;
        }
        h2{
          margin:6px 0;
        }
        p{
          color:#999;
          width:200px;
          margin-bottom:15px;
        }
        span{
          width:230px;
          position: relative;
          line-height:25px;
          i{
            width:8px;
            height:8px;
            display:inline-block;
            border-radius: 50%;
            background:#000;
            position: absolute;
            top: 7px;
          }
          u{
            display: inline-block;
            margin-left: 16px;
            text-decoration: none
          }
        }
      }
    }
  }
  .each{
    display: flex;
    background:#F4F7FE;
    .fp-tableCell {
      display: flex;
      flex-direction: column;
      padding-top: 105px;
    }
    .title{
      text-align: center;
      span.eachsss{
        color: #BCC2CE;
        font-size: 15px;
        display: block;
        font-weight: 500;
        margin-top: 14px;
      }
    }
    .each_div_img{
      text-align: center;
      .each_img{
        height:400px;
      }
    }
  }
  .page_esign_bg{
    background: url('../../../assets/image/page_bg.png') no-repeat bottom;
    background-size: 100% 77%;
    width:100%;
    height:100%;
    position: absolute;
    top: 0;
  }
  .page_esign{
    display: flex;
    background:#F4F7FE;
    .page_esign_bg{
      background: url('../../../assets/image/page_bg.png') no-repeat bottom;
      background-size: 100% 77%;
      width:100%;
      height:100%;
      position: absolute;
      top: 0;
    }
    .page_esign_all{
      display: flex;
      justify-content: center;
      .page_esign_left{
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        div{
          display: flex;
          flex-direction: column;
          line-height: 35px;
          justify-content: flex-end;
          margin-top:45px;
          span{
            cursor: pointer;
            font-size:14px;
            font-weight: 600;
            position: relative;
            z-index:100;
          }
          span.span_content{
            margin-left:10px;
          }
        }
      }
      .page_esign_right{
          width:50%;
        img{
          width:100%;
        }
      }

    }
    .fp-slidesNav ul li a.active span{
      background: #0ABD62;
    }
    .fp-slidesNav ul li a span{
      background: #a5a0a0
    }
  }
  .work_flow{
    display: flex;
    justify-content: center;
    background: url('../../../assets/image/page_bg.png') no-repeat bottom;
    background-size: 100% 77%;
    .work_flow_left{
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      div{
        display: flex;
        flex-direction: column;
        line-height: 35px;
        justify-content: flex-end;
        margin-top:45px;
        span{
          cursor: pointer;
          font-size:14px;
          font-weight: 600;
          position: relative;
          z-index:100;
        }
        span.span_content{
          margin-left:10px;
        }
      }
    }
      .work_flow_right{
        width:50%;
        img{
          width:100%;
        }
      }
    .fp-slidesNav ul li a.active span{
      background: #0ABD62;
    }
    .fp-slidesNav ul li a span{
      background: #a5a0a0
    }
  }
  span.choosees{
    color:#38DFAB !important;
    /*background-image:-webkit-linear-gradient(left,#18D2D5,#38DFAB,#5CE8A1) !important;
    -webkit-background-clip:text !important;
    -webkit-text-fill-color:transparent !important;*/
  }
  .footers_homepage{
    width:100%;
    position: absolute;
    bottom: 0;
  }
  .page_right_show{
    display:flex;
    flex-direction: column;
    position: relative;
    img{
      width: 8px !important;
      position: absolute;
      top: 13px;
    }
  }
  .page_esign_right_show{
    margin-left:16%;
    h1{
      margin-bottom:15px;
    }
    span{
      line-height: 33px;
      font-size: 13px;
      margin-left: 18px;
    }
  }
  .page_esign_right{
      .fp-tableCell{
        position: relative;
        top:-77px;
      }
  }
</style>
