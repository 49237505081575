<template>
  <div :style="{ height: bodyHeight + 'px' }" class="cases">
    <header>
      <div class="left">
        <div class="desc">
          <p>报价方案</p>
          <img src="../../../assets/image/pre_sale.png" alt="" />
        </div>
        <span>量身定做属于你的部署方案</span>
      </div>
      <div class="right"></div>
    </header>
    <section>
      <div class="item">
        <PriceCategory type="standard"/>
        <PriceConfig type="standard"/>
      </div>
      <div class="item">
        <PriceCategory type='enterprise'/>
        <PriceConfig type="enterprise"/>
      </div>
      <div class="item">
         <PriceCategory type='private' />
         <PriceConfig type="private"/>
      </div>
    </section>
     <footerS/>
  </div>
</template>
<script>
import footerS from '../footerS'
import PriceCategory from './price_category.vue'
import PriceConfig from './price_config.vue'
export default {
  name: 'price',
  components: {
    footerS,
    PriceCategory,
    PriceConfig
  },
  props: {
    bodyHeight: Number,
  },
}

</script>
<style lang="less" scoped>
header {
  background: #0abd62;
  height: 200px;
  display: flex;
  justify-content: space-between;
  .left {
    width: 360px;
    height: 200px;
    margin-left: 300px;
    background: url("../../../assets/image/price_banner_left.png") no-repeat;
    .desc {
      margin-top: 49px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      p {
        font-family: PingFangSC-Semibold;
        font-size: 40px;
        color: #ffffff;
        letter-spacing: 0;
        line-height: 60px;
        font-weight: 600;
      }
      img {
        margin-left: 10px;
      }
    }
    span {
      opacity: 0.8;
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 26px;
      font-weight: 500;
    }
  }
  .right {
    width: 645px;
    height: 200px;
    margin-right: 70px;
    background-image: url("../../../assets/image/price_banner_right.png");
    background-size: cover;
  }
}
section {
  padding: 70px 0;
  display: flex;
  justify-content: center;
  // height: 700px;
  .item {
    width: 416px;
    height: 675px;
    border-radius: 8px;
    border: 1px solid #eee;
    padding: 30px 32px;
    &:nth-of-type(1) {
      background-image: linear-gradient(180deg, #effffa 0%, #ffffff 100%);
    }
    &:nth-of-type(2) {
      background-image: linear-gradient(180deg, #effaff 0%, #ffffff 100%);
      margin: 0 30px;
    }
    &:nth-of-type(3) {
      background-image: linear-gradient(180deg, #fffefa 0%, #ffffff 100%);
    }
  }
}

</style>
