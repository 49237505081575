<template>
  <div class="home_page">
    <!--头部切换页面-->
    <header class="header">
      <img src="../../assets/image/company_logo.png" alt="">
<!--      <span class="registerOrLogin">注册/登录</span>-->
    </header>
    <div id="tabs">
      <TabList :tabData="tabs" @click="onClick" v-model="names">
        <homePageTab slot="homePageTab" :showNav="showNav"></homePageTab>
        <cases slot="cases" :bodyHeight="bodyHeight"></cases>
        <price slot="price" :bodyHeight="bodyHeight" @toOurs="onClick"></price>
        <helpCenter slot="helpCenter" :bodyHeight="bodyHeight"></helpCenter>
        <applyFor slot="applyFor" :bodyHeight="bodyHeight" @toOurs="onClick"></applyFor>
        <aboutOurs slot="aboutOurs" :bodyHeight="bodyHeight"></aboutOurs>
      </TabList>
    </div>
  </div>
</template>

<script>
import homePageTab from './operators/homePageTab'
import cases from './operators/cases'
import price from './operators/price'
import community from './operators/community'
import helpCenter from './operators/helpCenter'
import applyFor from './operators/applyFor'
import aboutOurs from './operators/aboutOurs'
import '../../static/fullpage.min.css'
export default {
  name: 'homePage',
  components: {
    homePageTab,
    cases,
    price,
    community,
    helpCenter,
    applyFor,
    aboutOurs
  },
  data () {
    return {
      tabs: [
        {
          name: '首页',
          component: 'homePageTab',
        },
        {
          name: '案例',
          component: 'cases',
        },
        {
          name: '价格',
          component: 'price',
        },
        {
          name: '帮助中心',
          component: 'helpCenter',
        },
        {
          name: '在线试用',
          component: 'applyFor',
        },
        {
          name: '关于我们',
          component: 'aboutOurs',
        },
      ],
      bodyHeight: '',
      names: '',
      showNav: true
    }
  },
  beforeMount () {
    this.bodyHeight = document.body.clientHeight
  },
  methods: {
    onClick (i) {
      /* this.$router.push({ path: '#' + i }) */
      this.names = i
      i === 'homePageTab' ? this.showNav = true : this.showNav = false
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.path === '/platform/') {
        this.$router.go(0)
      }
    }
  }
}
</script>

<style lang="less">
  ul li {
    list-style: none;
  }
  .home_page{
    height: 100%;
    position: relative;
    letter-spacing:1px;
    min-width: 1066px;
    .header{
      width:100%;
      background: #0ABD62;
      height:60px;
      padding: 0 100px;
      position: relative;
      z-index: 100;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img{
        width:188px;
        height: 30px;
      }
      .registerOrLogin{
        color:#fff;
        border: 1px solid #ffffff;
        padding: 5px 9px;
        font-size: 14px;
      }
    }
    #tabs{
      width:100%;
      height:100%;
      .ivu-tabs-nav-container:focus .ivu-tabs-tab-focused{
        color:#ffffff !important;
      }
      .ivu-tabs-ink-bar{
        background: #ffffff;
      }
      .ivu-tabs-nav .ivu-tabs-tab-active, .ivu-tabs-nav .ivu-tabs-tab{
        color: #ffffff;
        padding-top: 0;
        padding-bottom: 16px;
      }
      .zpa-tabs .ivu-tabs-bar{
        position: fixed;
        top: 24px;
        left: 300px;
        z-index:200;
      }
      .ivu-tabs-bar{
        border-bottom:1px solid transparent;
      }
    }
  }
.cases{
  overflow-y: auto;
}
::-webkit-scrollbar
{
  width: 6px;
  height: 16px;
  background-color: #F5F5F5;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track
{
  border-radius: 10px;
  background-color: #F5F5F5;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: #ccc;
}
</style>
