<template>
  <div class="detail">
    <div class="type">
      <img :src="info.src" alt="" />
      <p>{{info.label}}</p>
      <div class="price" v-if="type !== 'private'">
          ￥ <span>{{info.price}}</span>起/年
      </div>
       <div class="price" v-else>
           <p>{{info.price}}</p>
      </div>
    </div>
    <div class="btn " :class=" type == 'standard'?'btn-1':'btn-2'" v-if="type !== 'private'">立即购买</div>
    <div class="btn btn-3" v-else @click="touchOurs">联系我们</div>
  </div>
</template>
<script>
export default {
  computed: {
    info () {
      return this.imgSrc[this.type]
    }
  },
  methods: {
    touchOurs () {
      this.$parent.$emit('toOurs', 'aboutOurs')
    }
  },
  data () {
    return {
      imgSrc: {
        standard: {
          src: require('../../../assets/image/price_type_1.png'),
          label: '标准版',
          price: 7500
        },
        enterprise: {
          src: require('../../../assets/image/price_type_2.png'),
          label: '企业版',
          price: 15000
        },
        private: {
          src: require('../../../assets/image/price_type_3.png'),
          label: '私有化部署',
          price: '价格面议'
        },
      },
    }
  },
  props: {
    type: String
  }
}
</script>
<style lang="less" scoped>
.type {
  text-align: center;
  img {
    width: 120px;
    height: 120px;
  }
  p {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    color: #18283f;
    letter-spacing: 0;
    text-align: center;
    line-height: 36px;
    font-weight: 500;
  }
  .price {
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #18283f;
    letter-spacing: 0;
    text-align: center;
    line-height: 28px;
    font-weight: 400;
    width: 350px;
    height: 72px;
    span {
      font-family: DINAlternate-Bold;
      font-size: 48px;
      color: #18283f;
      letter-spacing: 0;
      text-align: center;
      line-height: 72px;
      padding: 0 5px;
    }
    p{
    font-family: PingFangSC-Semibold;
    font-size: 36px;
    color: #18283F;
    letter-spacing: 0;
    text-align: center;
    line-height: 72px;
    font-weight: 600;
    }
  }
}
.btn {
  width: 352px;
  height: 56px;
  border-radius: 4px;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 56px;
  font-weight: 500;
  cursor: pointer;
}
.btn-1 {
  background: #36d2a2;
}
.btn-2 {
  background: #12ace3;
}
.btn-3 {
  background: #d7c184;
}
</style>
