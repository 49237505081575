<template>
   <div :style="{height: bodyHeight + 'px'}" class="cases">
     <header></header>
     <div class="introduction">
       <div class="title">
         <h2>公司简介</h2>
         <div>
           <p>
             江苏南大先腾信息产业股份有限公司成立于2000年， 二十一年来专注于信息化解决方案和软件产品的研发。
             2012年以来公司以物联网、云计算、大数据和移动互联网等新一代信息技术的发展为契机,加大研发投入,在智慧
             城市、智慧政府和智慧企业等领域形成一系列先进解决方案和软件产品,公司从省内时长逐步走向全国市场。
           </p>
           <p>
             目前公司在北京、郑州、西安、重庆、衢州等地设有分支机构,公司员工约300人。
           </p>
           <p>
           公司技术团队从开发者角度出发,倾力研发乐扣低代码平台,降低信息化难度,让甲方业务人员和乙方实施人员参与到
           研发过程,通过对开发过程的程式化、可视化实现业务量化管理; 将业务模板化,便于知识积累;基础功能服务化,紧跟pass
           潮流;抽象基础设施接口,便于和第三方对接。
           </p>
         </div>
       </div>
       <span></span>
     </div>
     <div class="contact">
       <span class="imgs"></span>
       <div class="title">
         <div class="contact_ours">
           <div class="contact_ours_left">
             <h2>联系我们</h2>
             <p>地址: 南京市雨花台区宁双路18号沁恒科技园D栋5楼</p>
             <p>电话: 025-84207500-100</p>
             <p>邮箱: centit@centit.com</p>
           </div>
           <div class="contact_ours_right">
             <img src="../../../assets/image/gzh.png" alt="">
             <img src="../../../assets/image/lk.png" alt="">
           </div>
         </div>
         <div class="join_ours">
           <h2>加入我们</h2>
           <div class="recruit">
             <div v-for="(i, index) in joinOurs" :key="index">
              <h3>{{i.title}}</h3>
               <span>{{i.address}}     |     {{i.age}}     |     {{i.education}}</span>
              <p class="understand" :is="i.compent">
                  了解详情
                  <img src="../../../assets/image/ar_r.png" alt="">
              </p>
             </div>
             <p class="more" @click="toMore">
               更多<br>岗位
               <img src="../../../assets/image/ar_r.png" alt="">
             </p>
           </div>
           <p>电话: 025-84207500-823</p>
           <p>邮箱: wang_jin@centit.com</p>
         </div>
       </div>
     </div>
     <footerS/>
   </div>
</template>

<script>
import footerS from '../footerS'
import modalRequirement from './modalRequirement'
export default {
  name: 'aboutOurs',
  components: {
    footerS,
    modalRequirement,
  },
  props: {
    bodyHeight: Number,
  },
  data () {
    return {
      joinOurs: [
        {
          title: 'Java开发',
          address: '南京',
          age: '2年',
          education: '本科',
          compent: 'modalRequirement'
        },
        {
          title: '前端开发',
          address: '南京',
          age: '3年',
          education: '本科',
          compent: 'modalRequirement'
        },
        {
          title: '开发经理',
          address: '南京',
          age: '3-5年',
          education: '本科',
          compent: 'modalRequirement'
        }
      ]
    }
  },
  methods: {
    toMore () {
      window.open('http://www.centit.com/join/index.jhtml', '_blank')
    }
  }
}
</script>

<style lang="less">
header{
  background: url("../../../assets/image/banner_about.png") no-repeat;
  background-size: 100% 100%;
  height: 160px;
}
  .introduction {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 28px 0;
    .title{
      width:700px;
      font-size:14px;
      margin-right:25px;
      h2{
        margin-bottom: 40px;
        color: #000;
      }
      p{
        text-indent: 30px;
      }
    }
    span {
      background: url("../../../assets/image/work.png") no-repeat;
      background-size: 100% 100%;
      width:330px;
      height:330px;
      display: inline-block;
    }
  }
  .contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #F4F7FE;
    padding: 50px 0;
    span.imgs{
      background: url("../../../assets/image/map.png") no-repeat;
      background-size: 100% 100%;
      width:330px;
      height:330px;
      display: inline-block;
    }
    .title{
      width:700px;
      .contact_ours{
        display: flex;
        justify-content: space-around;
        .contact_ours_left{
          h2{
            margin-bottom:20px;
          }
          p{
            line-height: 25px;
            font-size:14px;
          }
        }
      }
      .join_ours{
        p{
          line-height: 25px;
          font-size:14px;
          img{
            margin-left:4px;
          }
        }
        .understand{
          cursor: pointer;
          color:#587EF7;
          span{
            display: flex;
            img{
              margin-left:4px;
            }
          }
        }
        .recruit{
          display: flex;
          justify-content: space-evenly;
          margin: 20px 0;
          div{
            background: url("../../../assets/image/post_bssg.png") no-repeat;
            background-size: 100% 100%;
            width:175px;
            height:112px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding-left: 13px;
          }
          p{
            color:#2D8CF0;
            display: flex;
            align-items: center;
          }
          .more{
            width: 65px;
            justify-content: center;
            background: #fff;
            border-radius: 3px;
            padding-left: 7px;
            cursor: pointer;
          }
          span{
            font-size:14px;
          }
        }
        h2{
          padding-left:28px;
        }
        > p {
          padding-left:39px;
        }
      }
    }
  }
</style>
