<template>
  <div>
    <ul class="configInfo">
      <li>
        <span>用户数</span>
        <span>{{info.userNum}}</span>
      </li>
      <li>
        <span>单价</span>
        <span>{{info.price}}</span>
      </li>
      <li>
        <span>数据库容量</span>
        <span>{{info.databaseCapacity}}</span>
      </li>
      <li>
        <span>文件/附件容量</span>
        <span>{{info.fileCapacity}}</span>
      </li>
      <li>
        <span>带宽</span>
        <span>{{info.bandwidth}}</span>
      </li>
      <li>
        <span>短信服务</span>
        <span>{{info.smsService}}</span>
      </li>
      <li>
        <span>代研发费用</span>
        <span>{{info.cost}}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    type: String
  },
  computed: {
    info () {
      return this.configInfo[this.type]
    }
  },
  data () {
    return {
      configInfo: {
        standard: {
          userNum: '50人起',
          price: '￥150/人/年',
          databaseCapacity: '4G/人',
          fileCapacity: '5G/人',
          bandwidth: '5M',
          smsService: '￥100/人/年',
          cost: '面议'
        },
        enterprise: {
          userNum: '50人起',
          price: '￥300/人/年',
          databaseCapacity: '8G/人',
          fileCapacity: '10G/人',
          bandwidth: '12M',
          smsService: '￥250/人/年',
          cost: '面议'
        },
        private: {
          userNum: '无限制',
          price: '——',
          databaseCapacity: '无限制',
          fileCapacity: '无限制',
          bandwidth: '无限制',
          smsService: '无限制',
          cost: '面议'
        },
      },
    }
  },
}
</script>
<style scoped lang="less">
.configInfo{
  li{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    span:nth-of-type(1){
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #828B97;
      letter-spacing: 0;
      line-height: 24px;
      font-weight: 400;
    }
    span:nth-of-type(2){
    //   font-family: PingFangSC-Medium;
      font-size: 16px;
      color: #18283F;
      letter-spacing: 0;
      text-align: right;
      line-height: 24px;
      font-weight: 600;
    }
  }
}
</style>
