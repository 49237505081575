<template>
  <!--<CoreApp :query-menu="queryMenu" app-name="南大先腾" />-->
  <div id="app">
    <transition name="fade" mode="out-in">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </transition>
  </div>

</template>

<script>
import CoreApp from '@centit/module-core/src/App'
import homePage from './components/extend/homePage'
export default {
  name: 'App',
  components: {
    CoreApp,
    homePage,
  },
  methods: {
    async queryMenu () {
      const dashboard = {
        id: 'Home',
        url: '/home',
        icon: 'md-home',
        text: '首页',
      }
      const about = {
        id: 'About',
        url: '/about',
        icon: 'md-people',
        text: '关于',
      }
      return [dashboard, about]
    }
  },
}
</script>
<style>
  html,body{
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    /*overflow: hidden;*/
  }
  #app{
    overflow-x: auto;
    letter-spacing:1.5px;
  }
</style>
