<template>
  <div :style="{height: bodyHeight + 'px'}" class="cases">
    <header>
      <div class="text_description">
        <p>乐扣为各个行业提供</p>
        <p>灵活、轻量、易用、方便定制的开发方式</p>
      </div>
    </header>
    <div class="cases_sp">
      <p class="title">典型案例</p>
      <div class="cases_sp_div">
        <ul>
          <li v-for="(i, index) in arrCase" :key="index">
            <img :src="i.src" alt="">
            <p>{{i.title}}</p>
            <span>{{i.text}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="cooperation_unit">
      <p class="title">为客户提供优质、用心的服务</p>
      <span>专业、专注、专心,支撑客户管理创新与业务突破,量身定制数字化管理方案</span>
    </div>
    <Carousel v-model="unit" loop arrow="always" dots="none">
      <CarouselItem>
        <div class="demo-carousel">
          <div class="carousel_1">
            <span v-for="(i, index) in arrUnit" :key="index">
              <img :src="i.src" alt="">
            </span>
          </div>
        </div>
      </CarouselItem>
    </Carousel>
    <footerS/>
  </div>
</template>

<script>
import footerS from '../footerS'
export default {
  name: 'homePageTab',
  components: {
    footerS
  },
  props: {
    bodyHeight: Number,
  },
  data () {
    return {
      arrCase: [
        {
          title: '考勤管理',
          src: require('../../../assets/image/kq.png'),
          text: '快速整合员工数据,提供企业人力信息的可视化分析.灵活建立报表,精细数据管理'
        },
        {
          title: '报销系统',
          src: require('../../../assets/image/bx.png'),
          text: '完善企业费用报销、票据管理、资金管理、预算管理及账务管理等财务活动'
        },
        {
          title: '人力资源管理',
          src: require('../../../assets/image/rl.png'),
          text: '规范化管理员工信息的电子建档,集中管理员工的基础信息、劳动合同'
        },
        {
          title: 'IT资产管理',
          src: require('../../../assets/image/it.png'),
          text: 'IT资产全息盘点,资产数据转化为可视化分析报表,提高效率降低企业成本'
        },
        {
          title: '合同管理',
          src: require('../../../assets/image/ht.png'),
          text: '企业合同电子化管理,有效达到规范管理的目的,规避履约风险,降低维护成本'
        },
        {
          title: '采购管理',
          src: require('../../../assets/image/cg.png'),
          text: '为企业提供需求管理,采购计划,采购寻源,采购执行,供应商协调对账和发票处理的采购···'
        },
        {
          title: '车辆管理系统',
          src: require('../../../assets/image/cl.png'),
          text: '企事业单位针对车辆用车、加油、维保、车检、违章等做线上统一管理'
        },
        {
          title: '项目管理系统',
          src: require('../../../assets/image/xm.png'),
          text: '提供项目执行,财务,分析及预测,全面把控项目周期,异常自动预警,项目财务分析等'
        },
        {
          title: '文件管理',
          src: require('../../../assets/image/wj.png'),
          text: '满足企业文档集中管控,安全存储,备份,打造企业统一的文档安全管控'
        },
      ],
      unit: 0,
      arrUnit: [
        { src: require('../../../assets/image/kh27.png') },
        { src: require('../../../assets/image/kh28.png') },
        { src: require('../../../assets/image/kh29.png') },
        { src: require('../../../assets/image/kh30.png') },
        { src: require('../../../assets/image/kh31.png') },
        { src: require('../../../assets/image/kh22.png') },
        { src: require('../../../assets/image/kh23.png') },
        { src: require('../../../assets/image/kh24.png') },
        { src: require('../../../assets/image/kh25.png') },
        { src: require('../../../assets/image/kh26.png') },
        { src: require('../../../assets/image/kh17.png') },
        { src: require('../../../assets/image/kh18.png') },
        { src: require('../../../assets/image/kh19.png') },
        { src: require('../../../assets/image/kh20.png') },
        { src: require('../../../assets/image/kh21.png') },
      ]
    }
  },
  methods: {
    reload () {
    }
  }
}
</script>

<style scoped lang="less">
header{
  background: url("../../../assets/image/case_banner.png") no-repeat;
  background-size: 100% 100%;
  height: 240px;
  padding-left: 296px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .text_description{
    color:#ffffff;
    p{
      font-size:31px;
      font-weight: 700;
    }
  }
}
p.title{
  font-size:31px;
  font-weight: 800;
  color:#2C3C5E;
}
  .cases_sp{
    display:flex;
    align-items: center;
    justify-content: center;
    padding:50px 0;
    flex-direction: column;
    background: #F4F7FE;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 1050px;
      li {
        display: flex;
        width: 323px;
        flex-direction: column;
        position: relative;
        p{
          position: absolute;
          bottom: 70px;
          left: 20px;
          font-size: 17px;
          font-weight: 700;
        }
        span{
          position: absolute;
          bottom: 26px;
          left: 20px;
          font-weight: 500;
          right: 16px;
          font-size: 13px;
        }
      }
    }
  }
  .cooperation_unit{
    display:flex;
    align-items: center;
    justify-content: center;
    padding-top:50px;
    flex-direction: column;
    span{
      margin: 12px 0 20px 0;
      font-size: 13px;
    }
  }
  .demo-carousel{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom:50px;
    .carousel_1{
      width:900px;
      margin-bottom:20px;
      text-align: center;
      span{
        display: inline-block;
        margin-right: 5px;
        img{
          width:170px;
        }
      }
    }
  }
</style>
